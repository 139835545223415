import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {
  hide = true;
  hide1 = true;
  hide2 = true;



  userType: any;
  message: string;
  imagePath: any;
  imgURL: any;
  uploaded: boolean = false;
  fileName: any;
  currentUser: any;
  passChangeMsg: boolean = false;
  modalRef: BsModalRef;

  submitted: boolean = false;
  changed: boolean = false;


  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  singalurl: any;
  selectedFile: any;
  @ViewChild('template2') template2: TemplateRef<any>;
  @ViewChild('myFile2') myFile2: ElementRef;

  // @HostListener("click")
  // clicked() {
  //   this.myFile2.nativeElement.value = '';
  //   // this.eventForm.controls['myFile'].setValue('');
  // }


  passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&*])[\w !@#$%&*]/;

  passwordForm = new FormGroup({
    oldPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8),Validators.pattern(this.passwordRegex)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]),
  })

  get p() {
    return this.passwordForm.controls;
  }

  constructor(
    private _header: HeaderService,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  lang: string = 'eng';
  ngOnInit(): void {
    let currentUser = localStorage.getItem('currentUser');
    this.userType = JSON.parse(currentUser).userType;
    this.lang = localStorage.getItem('lang');
    this._header.changePasswordHeading.next(true);
    this.currentUser = this.authService.currentUserValue;
  }
  ngOnDestroy() {
    this._header.changePasswordHeading.next(false);
  }

  // confirmation modal box
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  match: boolean = true;
  changePassword() {
    this.changed = true;
    if (this.passwordForm.invalid || this.passwordForm.pristine) return;
    this.changed = false;
    let data = {
      password: this.passwordForm.value.oldPassword,
      newPassword: this.passwordForm.value.newPassword,
    };
    if (this.passwordForm.value.newPassword == this.passwordForm.value.confirmPassword) {
      this.match = true;
      this.authService.changePassword(data).subscribe((res: any) => {
        console.log('changepassword res', res);
        if (res.status == 200) {
          this.passwordForm.controls['oldPassword'].reset();
          this.passwordForm.controls['newPassword'].reset();
          this.passwordForm.controls['confirmPassword'].reset();
          this.passChangeMsg = true;
          this.toastr.success(res.message);
          // this.router.navigateByUrl('/auth/login');
        } else {
          this.toastr.error(res.error.message);
        }
      });
    } else {
      this.match = false;
    }
  }
}
