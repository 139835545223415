import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageDiseasesService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'category/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'category/create', data);
    }

    updateInterest(data: any) {
        return this.http.patch(this.url + 'category/update', data);
    }

    deleteInterest(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'category/delete', httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }



    createCategory(data:any){
        return this.http.post(this.url + 'admin/Category/add', data);
    }

    
    deleteCategory(data:any){

        const httpOptions = {
            headers: new HttpHeaders({}), body: data
        };
        return this.http.delete(this.url + 'admin/category/delete',httpOptions);
    }

   

    editCategory(data:any){
        return this.http.post(this.url + 'admin/category/update', data);
    }

    getCategoryapi(data:any) {
        return this.http.post(this.url + 'admin/category/get', data);
    }


    // getDisease(data:any){
    //     // return this.http.post(this.url + 'disease/list', data);
    // }

    getCategoryList(data:any){
        return this.http.post(this.url + 'admin/category/list', data);
    }

    uploadImage(data:any){
        return this.http.post(this.url + 'file/upload', data);
    }
    
    // createCategory(data:any){
    //     return this.http.put(this.url + 'api/file/updateFiles', data);
    // }

    deleteDisease(data:any){
        // const httpOptions = {
        //     headers: new HttpHeaders({}), body: data
        // };
        return this.http.post(this.url + 'disease/delete', data);
    }

    diseaseEdit(data:any){
        return this.http.post(this.url + 'disease/edit', data);
    }
    
}