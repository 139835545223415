import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  constructor() {}

  manageCaregiversHeading = new BehaviorSubject(false);
  manageSubCategoriesHeading = new BehaviorSubject(false);

  manageClientHeading = new BehaviorSubject(false);
  manageServicesHeading = new BehaviorSubject(false);
  manageTutorialHeading = new BehaviorSubject(false);
  manageConvenienceHeading = new BehaviorSubject(false);
  manageDiseasesHeading = new BehaviorSubject(false);
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading =  new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  texHeading = new BehaviorSubject(false);


}
