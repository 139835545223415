import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean = false;
  modalRef: BsModalRef;



  @ViewChild("template") template: TemplateRef<any>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email])
  })

  ngOnInit(): void {
  }

  get f() {
    return this.resetForm.controls;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  sendEmailToLogin:any;

  // resetPassword() {
  //   this.submitted = true;
  //   if (this.resetForm.invalid || this.resetForm.pristine) return;
  //   this.submitted = false;
  
  //   const email = this.resetForm.value.email.toLowerCase();
  
  //   this.authService.forgetPassword(email)
  //     .subscribe((res: any) => {
  //       if (res.status == 200) {
  //         this.sendEmailToLogin = email;
  //         this.openModal(this.template);
  //         this.sendEmailToLogin = email;
  //         this.authService.SendToChild1(this.sendEmailToLogin);
  //         localStorage.setItem('receiveEmail', JSON.stringify(this.sendEmailToLogin));
  //       }
  //     },
  //     (err) => {
  //       console.log(err);
  //       this.toastr.error(err.error.errorCode);
        
  //     }
  //     );
  // }

  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;
  
    this.authService.forgetPassword({ email: this.resetForm.value.email.toLowerCase() })
      .subscribe(
        (res: any) => {
          if (res.status == 200) {
            this.sendEmailToLogin = this.resetForm.value.email;
            this.openModal(this.template);
            this.sendEmailToLogin = this.resetForm.value.email.toLowerCase();
            this.authService.SendToChild1(this.sendEmailToLogin);
            localStorage.setItem('receiveEmail', JSON.stringify(this.sendEmailToLogin));
          } 
          // else {
          //   this.toastr.error("This username is not registered with us");
          // }
        },
        (error) => {
          if (error.status === 400) {
            // Handle 400 response here
            this.toastr.error("This username is not registered with us");
            return; // Add return statement to prevent further execution
          }
          // Handle other errors
          this.toastr.error("An error occurred. Please try again later.");
        }
      );
  }
  
  
  

  OkPress(){
    this.modalRef.hide();
    // this.authService.SendToChild1(this.resetForm.value.email);
    // this.authService.SendToChild1(this.sendEmailToLogin);
    this.router.navigateByUrl('/login');
  }

}
