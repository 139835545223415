<div class="sidebar">
  <div class="logo mt-2"><img src="../../../assets/images/logo.svg" alt="logo" >
    <img src="../../../assets/images/name.svg" alt="logo" style="margin-left: -17px;"></div>

  <div class="header-links mt-4">
    <!-- rgb(26,219,188) -->
    <a routerLink="/manage/businesses" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Client.svg" alt="" height="24" width="24">
    </span>Manage Businesses</a>


    <a routerLink="/toplistings" routerLinkActive="active" >
      <!-- <a routerLink="/toplisting" routerLinkActive="active"> -->
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/mf_icons/Care.svg" alt="" height="24" width="24">
  </span>Manage Top Listings</a>
    
  <a routerLink="/manage/categories" routerLinkActive="active">
    <!-- <a routerLink="/manage/category" routerLinkActive="active"> -->
    <span class="sidebar-icon">
      <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage Local.svg" alt="" height="24" width="24">
     
    </span>
    <span style="white-space: nowrap;">Manage Categories</span> 
  </a>

  <a routerLink="/manage/subcategory" routerLinkActive="active">
    <!-- <a routerLink="/manage/subcategories" routerLinkActive="active"> -->
    <span class="sidebar-icon">
      <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage disease.svg" alt="" height="24" width="24">
    </span>
    <span style="white-space: nowrap;"> Manage Sub Category</span> 
  </a>
  

    <a routerLink="/services" routerLinkActive="active">
      <!-- <a routerLink="/service" routerLinkActive="active"> -->
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Services.svg" alt="" height="24" width="24">
    </span>Manage Services</a>

    <a routerLink="/reporting" routerLinkActive="active">
      <!-- <a routerLink="/analytics-dashboards" routerLinkActive="active"> -->
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/little/dashboard.svg" alt="">
    </span>Reporting</a>

    <a routerLink="/analytics-dashboard" routerLinkActive="active">
      <!-- <a routerLink="/manage/tax" routerLinkActive="active"> -->
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/MFcare Icons/Black/Manage convenience.svg" alt="" height="24" width="24">
    </span>Analytics</a>

   


    <a routerLink="/edit-profile" routerLinkActive="active">
      <!-- <a routerLink="/edit" routerLinkActive="active"> -->
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/little/Chnage Password Black.svg" alt="" height="24" width="24">
    </span>Change Password</a>



    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/little/Log Out Black.svg" alt="" height="24" width="24">
    </span>Logout</a>



<ng-template #template>
  <div class="modal-header">
      <button type="button" class="close pull-right close-button-black-border" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
      <img src="../../../../../assets/images/create-successfull-img.svg" alt="">
      <p>Are you sure you <br>want to logout?</p>
      <div class="modal-btn d-flex justify-content-around">
          <button class="btn btn-red" (click)="cancel()" style="color: #000;">No</button>
          <button class="btn btn-blue" (click)="confirm()" style="background-color: rgb(26,219,188)">Yes</button>
      </div>
  </div>
</ng-template>

  </div>
</div>